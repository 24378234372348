.basic-details-wrapper {
    padding: 0 15px;
}
.bread_style  {
    h5 {
        label{
            cursor: pointer;
           font-size: inherit;
           color: #F42634;
           &:after {
                content: '';
                background-image: url(../../../../../../assets/icons/brd_arw.png);
                background-size: 100%;
                background-repeat: no-repeat;
                display: inline-block;
                margin: 0 15px;
                width: 12px;
                height: 25px;
                vertical-align: middle;
            }
        }        
    }
}
.user-profile-pic  {
    figure{
        display: inline-block;
        width:120px;
        height:120px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #f5f5f5;
        img {
            max-width: 102%;
            height:auto;         
            min-width: 120px;
            min-height: 120px;   
        }
    }
     h2 {
        text-transform: capitalize;
        color: #5E5E5E;
        font-weight: 500;
        span {
            font-size: 14px;
            color: #4D4D4F;
            font-weight: 400;
        }
     }
     .email_address {
            margin: 0 0 10px;
            display: block;
    }
     h3 {
         font-size: 1.2rem;
     }
}

.user-address {
    @media (min-width: 768px) {
        border-left: 1px solid rgba(0,0,0,.1);
    } 
    li  {
        .info_user{
            display: inline-block;
            margin-bottom: 15px;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(0,0,0,.1);
            min-width: 250px;
            @media (max-width:575px) {
                min-width: 100%;
            }            
            label {
                display: block;
                margin-bottom: 7px;
                color: #5E5E5E;
                font-weight: 500;
                i {
                    margin-right: 7px;
                }
            }
        }
        &:last-child, &:nth-last-child(2), .lst-cls {
            .info_user {
                border-bottom: 0;
                padding-bottom: 0;
            }
        }    
    }    
}

.group-details {
    h6{
        font-weight: 500;
        margin: 0 0 10px;
    }
    label {
        font-weight: 400;
        color: #888888
    }
    .descriptive-details-wrapper {
        .form-group {
            margin-bottom: 1rem;
        }
    }
}

h6.user-role {
    font-weight: 400;
    background: rgb(129,207,243);
    border-radius: 20px;
    padding: 5px 0px;
    max-width: 100px;
    text-align: center;
    align-self: center;
    margin: auto;
    color: white;
    text-transform: capitalize;
}