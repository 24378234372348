.profile_pic {	
	width:170px;
	margin:0 auto;
	.user_pic {
		border:4px solid #F42634;
		border-radius: 50%;
		overflow: hidden;
		display: inline-block;
		width:170px;
		height:170px;
		img { 
			min-height: 164px;
			   width: 164px;
			   object-fit: cover;
		}
	}
}
.upload-photo {
   width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.upload_pic {
	position: absolute;
	top:0px;
	right:10px;
	i {
		display:flex;
		align-items: center;
		justify-content: center;
		background-color: #F42634;
		width:42px;
		height:42px;
		border-radius: 25px;
		text-align: center;
		cursor: pointer;
	}
}

.profile_detail {	
	padding-top:15px;
	@media (min-width:992px){
		border-left:1px solid #E5E5E5;
	}
	.label_field {
		padding:10px 0;
		border-bottom:1px solid #E5E5E5;
	}
}

.title-text {
	text-transform: capitalize;
	margin: 0 0 1rem;
	color: #4d4d4f;
  }